import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import cls from 'classnames';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { getText } from '@wix/ricos';

import { selectCurrentUser, selectDraft } from 'store/selectors';
import * as feed from 'settings/feed';

import { Button } from 'wui/Button';
import { Card } from 'wui/Card';
import { Box } from 'wui/Box';
import { CardContent } from 'wui/CardContent';
import { CardActionArea } from 'wui/CardActionArea';
import { CardHeader } from 'wui/CardHeader';
import { TextField } from 'wui/TextField';
import { TextButton } from 'wui/TextButton';
import { Divider } from 'wui/Divider';
import { Avatar } from 'wui/Avatar';
import { type TPAComponentProps } from 'wui/types';
import {
  Camera as CameraIcon,
  Video as VideoIcon,
} from '@wix/wix-ui-icons-common/on-stage';

import classes from './CreatePost.scss';

export interface ICreatePostProps extends TPAComponentProps {
  className?: string;

  onClick(): void;
}

export function CreatePost(props: ICreatePostProps) {
  const { onClick, ...rest } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const user = useSelector(selectCurrentUser);
  const draft = useSelector(selectDraft);

  const draftText = useMemo(
    () => (draft ? getText(draft).join(' ').trim() : undefined),
    [draft],
  );

  const placeholder =
    settings.get(feed.settings.newPostBoxLabel) ||
    t('groups-web.discussion.create-post.placeholder');

  if (isMobile) {
    return (
      <Card sideBorders={false} {...rest}>
        <CardContent>
          <Button fullWidth onClick={onClick}>
            {t('groups-web.discussion.new-post.cta')}
          </Button>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card padding="SP0" gap="SP0" className={classes.root} {...rest}>
      <CardActionArea
        gap="SP0"
        onClick={onClick}
        data-hook="create-post-action-area"
        aria-label={t('groups-web.discussion.new-post.cta')}
      >
        <CardHeader
          padding="SP5"
          avatar={<Avatar src={user.imageUrl} lettersLimit={2} />}
          action={
            <Button tabIndex={-1}>
              {t('groups-web.discussion.new-post.cta')}
            </Button>
          }
          title={
            <TextField
              wired
              disabled
              tabIndex={-1}
              inputClassName={classes.input}
              value={draftText || placeholder}
              className={cls(classes.textField, {
                [classes.empty]: !draftText,
              })}
            />
          }
        />
        <Divider />
      </CardActionArea>
      <Box
        padding="SP2 SP0"
        align="space-between"
        className={classes.pluginBar}
      >
        <Box width="100%" align="center">
          <TextButton
            tabIndex={-1}
            variant="secondary"
            onClick={onClick}
            prefixIcon={<CameraIcon />}
            aria-label={t(
              'groups-web.discussion.new-post.plugins.image.aria-label',
            )}
          >
            {t('groups-web.discussion.new-post.plugins.image')}
          </TextButton>
        </Box>

        <Divider orientation="vertical" />

        <Box width="100%" align="center">
          <TextButton
            tabIndex={-1}
            variant="secondary"
            onClick={onClick}
            prefixIcon={<VideoIcon />}
            aria-label={t(
              'groups-web.discussion.new-post.plugins.video.aria-label',
            )}
          >
            {t('groups-web.discussion.new-post.plugins.video')}
          </TextButton>
        </Box>

        <Divider orientation="vertical" />

        <Box width="100%" align="center">
          <TextButton
            tabIndex={-1}
            variant="secondary"
            onClick={onClick}
            aria-label={t(
              'groups-web.discussion.new-post.plugins.gif.aria-label',
            )}
          >
            GIF
          </TextButton>
        </Box>
      </Box>
    </Card>
  );
}

CreatePost.displayName = 'CreatePost';
