import React from 'react';
import { useSelector } from 'react-redux';
import cls from 'classnames';

import { EditorEventsProvider } from '@wix/ricos';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import {
  groupCreatePostClick,
  groupFeedView,
  groupInviteMembersOptionClicked,
} from '@wix/bi-logger-groups/v2';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';
import { useController } from 'common/context/controller';
import { EmptyState } from 'wui/EmptyState';
import { Wire } from 'wui/Wire';
import { Show } from 'wui/Show';
import { Box } from 'wui/Box';

import { EGroupsNamespace } from 'store/groups/constants';
import {
  selectFeed,
  selectFeedItems,
  selectGlobalFeedPermissions,
  selectGroups,
  selectIsSiteAdmin,
} from 'store/selectors';

import { FeedItemEditorDialog } from 'common/components/FeedItemEditorDialog';
import { FeedItemList } from 'common/components/FeedItemList';

import { CreatePost } from 'Group/Widget/DiscussionPage/GroupFeed/CreatePost';
import { InviteMembersDialog } from 'Group/Widget/Header/InviteMembersDialog';

import settingsParams from 'Groups/settingsParams';

import { InviteMembersCard } from './InviteMembersCard';
import { SelectGroupDialog } from './SelectGroupDialog';

import { selectShouldShowInviteMembers } from './selectors';

import classes from './CentralFeed.scss';

type DialogsState = {
  createPost: boolean;
  selectGroup: boolean;
  inviteMembers: boolean;
};

export const CentralFeed: React.FC<TPAComponentProps> = (props) => {
  const bi = useBi();
  const { t } = useTranslation();
  const { centralFeed$ } = useController();
  const settings = useSettings();

  const state = useSelector(selectFeed);
  const items = useSelector(selectFeedItems);

  const [selected, setSelected] = React.useState<string>();
  const actionForGroup = React.useRef<() => void>();

  const [dialogs, _setDialogs] = React.useState<DialogsState>({
    createPost: false,
    selectGroup: false,
    inviteMembers: false,
  });

  const prevDialogs = React.useRef<DialogsState>({
    createPost: false,
    selectGroup: false,
    inviteMembers: false,
  });

  const isSiteAdmin = useSelector(selectIsSiteAdmin);
  const showInviteMembersCard = useSelector(selectShouldShowInviteMembers);
  const globalFeedPermissions = useSelector(selectGlobalFeedPermissions);
  const { groups: joinedGroups } = useSelector(
    selectGroups(EGroupsNamespace.JOINED),
  );

  React.useEffect(() => {
    bi.report(
      groupFeedView({
        container: 'groups',
        roles: isSiteAdmin ? 'admin' : 'member',
      }),
    );
  }, []);

  return (
    <Box direction="vertical" className={cls(classes.root, props.className)}>
      <Wire
        cssVarName="showCreatePost--flex"
        legacyFallback={settings.get(settingsParams.showCreatePost)}
      >
        <Show if={globalFeedPermissions?.canCreatePosts}>
          <CreatePost onClick={handleActionForGroup(openCreatePostDialog)} />
        </Show>
      </Wire>

      <FeedItemList
        state={state}
        items={items}
        showGroupName
        className={classes.list}
        onCreatePost={handleActionForGroup(openCreatePostDialog)}
        onFetch={handleFetchMore}
        slots={{
          empty: (
            <EmptyState
              title={t('groups-web.no-posts.title')}
              subtitle={t('groups-web.no-posts.content')}
            />
          ),
        }}
      />

      <Show if={showInviteMembersCard}>
        <InviteMembersCard
          onClick={handleActionForGroup(openInviteMembersDialog)}
        />
      </Show>

      <SelectGroupDialog
        isOpen={dialogs.selectGroup}
        onClose={closeDialogs}
        onSelect={handleGroupSelect}
      />

      <InviteMembersDialog
        isOpen={dialogs.inviteMembers}
        groupId={selected as string}
        onClose={closeDialogs}
      />

      <EditorEventsProvider>
        <FeedItemEditorDialog
          promoteGroup
          groupId={selected}
          onClose={closeDialogs}
          isOpen={dialogs.createPost}
          onCancel={handleBackButtonClick}
          cancelLabel={t('groups-web.discussion.new-post.back')}
          biExtra={{
            page_name: 'livesite_group_list',
            origin: 'livesite_group_list',
          }}
        />
      </EditorEventsProvider>
    </Box>
  );

  function handleFetchMore(cursor?: string) {
    centralFeed$.fetch(cursor, true);
  }

  function handleActionForGroup(cb: () => void) {
    return () => {
      actionForGroup.current = cb;

      if (
        joinedGroups?.length === 1 &&
        joinedGroups[0].permissions?.feed?.canCreatePosts
      ) {
        setSelected(joinedGroups[0].id as string);
        cb();
      } else {
        openSelectGroupDialog();
      }
    };
  }

  function openCreatePostDialog() {
    bi.report(
      groupCreatePostClick({
        origin: 'new_layout_groups_sidebar',
      }),
    );

    setDialogs({
      createPost: true,
      selectGroup: false,
      inviteMembers: false,
    });
  }

  function openInviteMembersDialog() {
    bi.report(
      groupInviteMembersOptionClicked({
        origin: 'invite_members_sidebar',
        optionType: 'invite',
      }),
    );
    setDialogs({
      createPost: false,
      selectGroup: false,
      inviteMembers: true,
    });
  }

  function openSelectGroupDialog() {
    setDialogs({
      createPost: false,
      selectGroup: true,
      inviteMembers: false,
    });
  }

  function closeDialogs() {
    setDialogs({
      createPost: false,
      selectGroup: false,
      inviteMembers: false,
    });
  }

  function handleGroupSelect(groupId: string) {
    setSelected(groupId);
    actionForGroup?.current?.();
  }

  function handleBackButtonClick() {
    setDialogs(prevDialogs.current);
  }

  function setDialogs(nextDialogs: DialogsState) {
    _setDialogs((dialogs) => {
      prevDialogs.current = dialogs;
      return nextDialogs;
    });
  }
};

CentralFeed.displayName = 'CentralFeed';
